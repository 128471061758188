@import url("https://fonts.googleapis.com/css2?family=Play:wght@400;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Planer Regular";
    font-style: normal;
    font-weight: normal;
    src: url(./assets/font/Planer_Reg.woff) format("woff");
  }
}
