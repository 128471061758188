.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  max-width: 300px;
  width: 100%;
  height: 100%;
  transition: all 0.5s;
  z-index: 999999999;
  background-color: transparent;
}
.dashboard-left {
  max-width: 300px;
  width: 100%;
}

.sidebar-logo {
  margin: 30px 0 60px;
}
.custom-link.active {
  color: #00ff00;
  border: 1px solid #13cfcf11;
  background: #13182357;
  box-shadow: 12px 12px 22px rgb(0 0 0 / 25%);
  border-radius: 63px;
  backdrop-filter: blur(15px);
}
@media (max-width: 1200px) {
  .dashboard-left {
    position: fixed;
    z-index: 99999;
  }
  .sidebar {
    left: auto;
    bottom: auto;
    right: -100%;
    z-index: 99999999;
    /* height: calc(100% - 75px); */
    top: 0px;
    background-color: rgb(13, 15, 25);
    opacity: 1;
  }
  .sidebar.active {
    right: 0%;
  }
  /* .sidebar-logo {
    display: none;
  } */
}
